<template>
  <content-template
    title="Unauthorized"
    contained
  >
    <content-container
      description="Sorry, you are unauthorized to view this information."
      class="unauthorized-view"
    >
      <router-link :to="{ name: 'home' }">
        Go home.
      </router-link>
    </content-container>
  </content-template>
</template>

<script>
import ContentTemplate from '../components/ContentTemplate/ContentTemplate'
import ContentContainer from '../components/ContentContainer/ContentContainer'

export default {
  components: {
    ContentTemplate,
    ContentContainer,
  },
}
</script>

<style lang="scss">
.unauthorized-view {
  a {
    @include mds-link($visited-styles: false);
  }
}
</style>
